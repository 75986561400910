<template>
  <v-container>
    <div v-if="!overlay">
      <v-btn
        fab
        dark
        fixed
        bottom
        large
        :left="$vuetify.rtl"
        :right="!$vuetify.rtl"
        color="primary"
        @click="scrollToTop()"
        :style="$vuetify.rtl ? 'margin-left:80px' : 'margin-right:80px'"
        v-if="showScrollTop"
      >
        <v-icon> mdi-arrow-up</v-icon>
      </v-btn>

      <v-speed-dial
        v-if="isInRole(2)"
        v-model="fab"
        fixed
        bottom
        :left="$vuetify.rtl"
        :right="!$vuetify.rtl"
        :class="$store.state.drawerMessage ? 'ml-16' : ''"
        transition="slide-y-reverse-transition"
      >
        <template v-slot:activator>
          <v-btn v-model="fab" color="secondary" fab large elevation="6">
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-plus </v-icon>
          </v-btn>
        </template>
        <v-tooltip :left="!$vuetify.rtl" :right="$vuetify.rtl">
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              :disabled="isActive"
              color="accent"
              fab
              dark9
              @click="importQuestionsDialog = true"
              v-on="{ ...tooltip }"
            >
              <v-icon> mdi-import</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("exam.importQuestions") }}</span>
        </v-tooltip>
        <v-tooltip :left="!$vuetify.rtl" :right="$vuetify.rtl" v-if="!this.isBankQuestions">
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              @click="openAddQuestionFromBank"
              :disabled="isActive"
              color="teal"
              fab
              dark
              v-on="{ ...tooltip }"
            >
              <v-icon> mdi-content-save-plus</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("exam.AddAQuestionFromBank") }}</span>
        </v-tooltip>

        <v-tooltip :left="!$vuetify.rtl" :right="$vuetify.rtl">
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              :disabled="isActive"
              color="primary"
              fab
              dark
              @click="openAddQuestion"
              v-on="{ ...tooltip }"
            >
              <v-icon> mdi-file-question</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("exam.AddAQuestion") }}</span>
        </v-tooltip>
      </v-speed-dial>

      <v-row>
        <v-col cols="12">
          <v-text-field
            hide-details
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('writeWhatYouSearch')"
            outlined
            dense
          >
            <template v-slot:append-outer>
              <v-card-actions style="margin-top: -6px" class="pt-0">
                <v-btn color="secondary" @click="exportToXlsx" :loading="loadingData">
                  {{ $t("Export") }}
                  <v-icon class="px-2">mdi-microsoft-excel</v-icon>
                </v-btn>
                <v-btn
                  v-if="examGuid && !isBankQuestions"
                  color="primary"
                  :to="'/enterExam=' + examGuid"
                  target="_blank"
                >
                  {{ $t("Preview") }}
                  <v-icon class="px-2">mdi-eye-outline</v-icon>
                </v-btn>
              </v-card-actions>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <div v-if="!examQuestions[0]" class="text-center pa-4">
        <img src="../../assets/document.svg" width="200px" />
        <h3>{{ $t("exam.ThereAreNoQuestions") }}</h3>
      </div>
      <v-card-actions
        style="margin-top: -9px"
        class="pt-0 mb-2"
        v-if="examQuestions && examQuestions[0]"
      >
        <v-btn
          icon
          light
          :color="currentView == 'large' ? 'primary' : ''"
          @click="setView('large')"
        >
          <v-icon>mdi-grid-large</v-icon>
        </v-btn>
        <v-btn
          icon
          light
          :color="currentView == 'details' ? 'primary' : ''"
          @click="setView('details')"
        >
          <v-icon>mdi-view-list</v-icon>
        </v-btn>
      </v-card-actions>

      <v-row v-resize="onResize" v-if="questionsFilter && questionsFilter[0]">
        <v-col
          cols="12"
          lg="12"
          md="12"
          sm="12"
          v-if="currentView == 'details'"
          class="ma-0 pa-0 mt-1"
        >
          <v-data-table
            :items="questionsFilter"
            item-key="questionGuid"
            :loading="loadingData"
            :headers="headersQuestions"
            :search="questionsSearch"
            class="elevation-1"
            selectable-key="questionGuid"
            v-model="selectedQuestions"
            :expanded.sync="expanded"
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-card-text class="ma-0 pa-0 pb-2" v-if="item.containsFile && item.fileUrl">
                  <v-list-item
                    class="pa-2"
                    v-if="
                      ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(get_url_extension(item.fileUrl))
                    "
                  >
                    <v-list-item-avatar class="ma-0" tile size="100">
                      <v-img
                        lazy-src="../../assets/loadImg.svg"
                        :src="item.fileUrl"
                        width="100"
                        max-width="400"
                      >
                      </v-img>
                    </v-list-item-avatar>
                  </v-list-item>
                  <download-file v-else :fileUrl="item.fileUrl" class="my-2"></download-file>
                </v-card-text>
              </td>
            </template>

            <template v-slot:[`item.rowActions`]="{ item }">
              <v-menu>
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{}">
                      <v-btn
                        class="px-2"
                        depressed
                        style="margin: 0px !important"
                        v-bind="attrs"
                        v-on="{ ...menu }"
                      >
                        <v-icon>mdi-dots-vertical</v-icon>
                        {{ $t("options") }}
                      </v-btn>
                    </template>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-list-item v-if="!isActive" @click="editQuestion(item)">
                    <v-list-item-icon
                      ><v-icon color="teal">mdi-file-edit-outline</v-icon></v-list-item-icon
                    >
                    <v-list-item-title>{{ $t("edit") }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="!isActive" @click="deleteQuestion(item.questionGuid)">
                    <v-list-item-icon
                      ><v-icon color="error">mdi-delete-forever-outline</v-icon></v-list-item-icon
                    >
                    <v-list-item-title>{{ $t("delete") }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="!isBankQuestions" @click="addToBankDialog(item.questionGuid)">
                    <v-list-item-icon
                      ><v-icon color="primary"
                        >mdi-frequently-asked-questions</v-icon
                      ></v-list-item-icon
                    >
                    <v-list-item-title>{{ $t("exam.addToQuestionsBank") }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:[`item.question`]="{ item }">
              <v-card-text class="ma-0 pa-0 pb-2">
                <v-btn
                  :key="item.questionGuid"
                  icon
                  @click="showAttachments(item)"
                  v-if="item.containsFile && item.fileUrl"
                  :title="$t('mail.Attachments')"
                >
                  <v-icon color="primary">mdi-attachment</v-icon>
                </v-btn>
                {{ item.question }}
              </v-card-text>
            </template>
            <template v-slot:[`item.questionType`]="{ item }">
              <v-chip
                :title="
                  item.questionAnswers != null
                    ? item.questionAnswers.map(i => i.questionAnswer).join('\n')
                    : null
                "
              >
                {{
                  `${
                    item.questionType == "MultiChoice"
                      ? $t("exam.MultipleChoices")
                      : item.questionType == "OneChoice"
                      ? $t("exam.ChooseOne")
                      : item.questionType == "FillInTheBlanks"
                      ? $t("exam.FillInTheBlank")
                      : item.questionType == "Number"
                      ? $t("exam.FillInTheBlankNumericValue")
                      : item.questionType == "AttachedFile"
                      ? $t("exam.UploadAFile")
                      : ""
                  } `
                }}</v-chip
              >
            </template>
            <template v-slot:[`item.lastUsedDate`]="{ item }">
              {{ item.lastUsedDate | moment("YYYY/MM/DD - h:mm A") }}
            </template>
            <template v-slot:[`item.questionAnswers`]="{ item }">
              <v-list dense v-if="item.questionAnswers && item.questionAnswers.length > 0">
                <v-list-item
                  link
                  style="min-height: 0px"
                  v-for="answer in item.questionAnswers"
                  :key="answer.questionAnswer"
                >
                  <v-list-item-icon class="me-0 my-0">
                    <v-icon small :color="answer.isCorrect ? 'primary' : 'secondary'">
                      {{ answer.isCorrect ? "mdi-check-bold" : "mdi-close-thick" }}
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content class="py-0">
                    <v-list-item-title style="white-space: break-spaces">{{
                      answer.questionAnswer
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row v-if="visiblePages && visiblePages[0]">
        <v-col cols="12" lg="12" md="12" sm="12" v-if="currentView == 'large'">
          <v-row class="ma-0">
            <v-col cols="12" md="12" class="ma-0 pa-0">
              <v-pagination
                v-if="examQuestions[0]"
                v-model="page"
                :length="Math.ceil(questionsFilter.length / perPage)"
                :total-visible="perPage"
              ></v-pagination>
            </v-col>
          </v-row>
          <v-row v-for="item in visiblePages" :key="item.questionGuid" class="ma-0">
            <v-col cols="12" md="12" class="ma-1 pa-0">
              <v-card class="mx-auto" flat outlined>
                <v-card-text>
                  <question-title :examQuestion="item"></question-title>

                  <!-- MultiChoice MultiChoice MultiChoice MultiChoice MultiChoice MultiChoice MultiChoice MultiChoice  -->
                  <div class="text--primary" v-if="item.questionType === 'MultiChoice'">
                    <v-alert outlined color="primary" text>
                      <div v-for="multeItem in item.questionAnswers" :key="multeItem.answerGuid">
                        <v-checkbox
                          class="pa-1 ma-0"
                          hide-details
                          readonly
                          v-model="multeItem.isCorrect"
                          :label="multeItem.questionAnswer"
                          :value="multeItem.isCorrect"
                          :color="multeItem.isCorrect ? 'success' : 'error'"
                        >
                        </v-checkbox>
                      </div>
                    </v-alert>
                  </div>
                  <!-- OneChoice OneChoice OneChoice OneChoice OneChoice OneChoice OneChoice OneChoice OneChoice  -->
                  <div class="text--primary" v-if="item.questionType === 'OneChoice'">
                    <v-alert outlined color="primary" text>
                      <div
                        v-for="oneChoesVal in item.questionAnswers"
                        :key="oneChoesVal.answerGuid"
                      >
                        <v-radio-group
                          v-if="oneChoesVal.isCorrect"
                          v-model="oneChoesVal.questionAnswer"
                        >
                          <v-radio
                            readonly
                            v-for="oneChoesItem in item.questionAnswers"
                            :key="oneChoesItem.answerGuid"
                            :label="oneChoesItem.questionAnswer"
                            :value="oneChoesItem.questionAnswer"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                    </v-alert>
                  </div>
                  <!-- FillInTheBlanks FillInTheBlanks FillInTheBlanks FillInTheBlanks FillInTheBlanks FillInTheBlanks -->
                  <div class="text--primary" v-if="item.questionType === 'FillInTheBlanks'">
                    <v-alert
                      outlined
                      color="primary"
                      text
                      v-if="item.questionAnswers && item.questionAnswers.length > 0"
                    >
                      {{ item.questionAnswers[0].questionAnswer }}
                    </v-alert>
                  </div>
                  <!-- Number Number Number Number Number Number Number Number Number Number Number Number Number Number -->
                  <div class="text--primary" v-if="item.questionType === 'Number'">
                    <v-alert
                      outlined
                      color="primary"
                      text
                      v-if="item.questionAnswers && item.questionAnswers.length > 0"
                    >
                      {{ item.questionAnswers[0].questionAnswer }}
                    </v-alert>
                  </div>
                  <!-- AttachedFile AttachedFile AttachedFile AttachedFile AttachedFile AttachedFile AttachedFile AttachedFile  -->
                  <div
                    class="text--primary"
                    v-if="item.questionType === 'AttachedFile' && item.studentAnswers"
                  >
                    <v-alert outlined color="primary" text>
                      <download-file
                        v-if="item.studentAnswers[0]"
                        :fileUrl="item.fileUrl"
                      ></download-file>

                      <p v-else class="pt-2 error--text">
                        {{ $t("exam.NoFileSent") }}
                      </p>
                    </v-alert>
                  </div>
                </v-card-text>
                <v-card-actions class="mx-2 mb-3">
                  <v-btn outlined color="teal" @click="editQuestion(item)" :disabled="isActive">
                    {{ $t("edit") }}
                    <v-icon>mdi-file-edit-outline</v-icon>
                  </v-btn>
                  <v-btn
                    outlined
                    color="error"
                    @click="deleteQuestion(item.questionGuid)"
                    :disabled="isActive"
                  >
                    {{ $t("delete") }}
                    <v-icon>mdi-delete-forever-outline</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="!isBankQuestions"
                    outlined
                    color="primary"
                    @click="addToBankDialog(item.questionGuid)"
                  >
                    {{ $t("exam.addToQuestionsBank") }}
                    <v-icon class="px-1">mdi-frequently-asked-questions</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-pagination
                v-if="examQuestions[0]"
                v-model="page"
                :length="Math.ceil(questionsFilter.length / perPage)"
                :total-visible="perPage"
              ></v-pagination>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <AddQuestion
      ref="addQuestionComponent"
      v-on:send-result-values="sendResultValues"
    ></AddQuestion>

    <!-- Add AQuestions From Bank -->
    <v-dialog
      v-if="!isBankQuestions"
      transition="dialog-bottom-transition"
      fullscreen
      v-model="dialogBank"
      persistent
      no-click-animation
    >
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-toolbar-title> {{ $t("exam.AddAQuestionFromBank") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialogBank = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-autocomplete
            outlined
            class="py-3"
            hide-details
            dense
            :items="bankCategories"
            item-text="categoryName"
            item-value="categoryGuid"
            :placeholder="$t('exam.selectQuestionsBank')"
            @change="getBankQuestion"
            v-model="selectedCategory"
          >
            <!-- <template v-slot:selection="{ item }">
              <v-chip>
                <span>{{ item.categoryName }}</span>
              </v-chip>
            </template> -->
            <template v-slot:item="{ item }">
              <span> {{ item.categoryName }} </span>
              <span v-if="item.categoryDescription" class="px-2">
                ({{ item.categoryDescription }})</span
              >
              <v-spacer></v-spacer>
              <v-chip outlined color="green" v-if="item.studySubject">
                <span>{{ item.studySubject.subjectName }}</span>
              </v-chip>
            </template>
            <template v-slot:append-outer>
              <v-checkbox
                class="mt-0 pt-0"
                id="allBanks"
                v-model="allBanks"
                hide-details
                :label="$t('all')"
                :key="allBanks"
                @change="getBankCategories"
              >
              </v-checkbox>
            </template>
          </v-autocomplete>
          <v-row class="ma-0 pb-3">
            <v-text-field
              outlined
              v-model="questionsSearch"
              hide-details
              append-icon="mdi-magnify"
              :label="$t('writeWhatYouSearch')"
              dense
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="addSelectedQuestionsFromBank"
              :disabled="!selectedQuestions.length > 0"
            >
              {{ $t("add") }}
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-row>

          <v-data-table
            :items="bankQuestions.examQuestionsBank"
            item-key="questionGuid"
            show-select
            :loading="loadingData"
            :headers="headers"
            :search="questionsSearch"
            class="elevation-1"
            selectable-key="questionGuid"
            v-model="selectedQuestions"
          >
            <template v-slot:[`item.questionType`]="{ item }">
              <v-chip
                :title="
                  item.questionAnswers != null
                    ? item.questionAnswers.map(i => i.questionAnswer).join('\n')
                    : null
                "
              >
                {{
                  `${
                    item.questionType == "MultiChoice"
                      ? $t("exam.MultipleChoices")
                      : item.questionType == "OneChoice"
                      ? $t("exam.ChooseOne")
                      : item.questionType == "FillInTheBlanks"
                      ? $t("exam.FillInTheBlank")
                      : item.questionType == "Number"
                      ? $t("exam.FillInTheBlankNumericValue")
                      : item.questionType == "AttachedFile"
                      ? $t("exam.UploadAFile")
                      : ""
                  } `
                }}</v-chip
              >
            </template>
            <template v-slot:[`item.lastUsedDate`]="{ item }">
              {{ item.lastUsedDate | moment("YYYY/MM/DD - h:mm A") }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- The Questions Is Already Added From The Bank -->
    <v-dialog
      v-if="!isBankQuestions"
      transition="dialog-bottom-transition"
      v-model="dialogBankAlreadyAdded"
      width="800"
    >
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-btn icon dark @click="dialogBankAlreadyAdded = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> {{ $t("exam.alreadyAddedTheQuestionsFromTheBank") }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-data-table
            :items="alreadyAddedQuestions"
            item-key="questionGuid"
            :headers="headers"
            class="elevation-1"
          >
            <template v-slot:[`item.questionType`]="{ item }">
              <v-chip
                :title="
                  item.questionAnswers != null
                    ? item.questionAnswers.map(i => i.questionAnswer).join('\n')
                    : null
                "
              >
                {{
                  `${
                    item.questionType == "MultiChoice"
                      ? $t("exam.MultipleChoices")
                      : item.questionType == "OneChoice"
                      ? $t("exam.ChooseOne")
                      : item.questionType == "FillInTheBlanks"
                      ? $t("exam.FillInTheBlank")
                      : item.questionType == "Number"
                      ? $t("exam.FillInTheBlankNumericValue")
                      : item.questionType == "AttachedFile"
                      ? $t("exam.UploadAFile")
                      : ""
                  } `
                }}</v-chip
              >
            </template>
            <template v-slot:[`item.lastUsedDate`]="{ item }">
              {{ item.lastUsedDate | moment("YYYY/MM/DD - h:mm A") }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Add Questions To The Bank -->
    <v-dialog v-if="!isBankQuestions" v-model="dialogBankAddQuestionToBank" width="700">
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-toolbar-title> {{ $t("exam.addToQuestionsBank") }}</v-toolbar-title
          ><v-spacer></v-spacer>
          <v-btn icon dark @click="dialogBankAddQuestionToBank = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-autocomplete
            outlined
            class="py-3"
            hide-details
            dense
            :items="bankCategories"
            item-text="categoryName"
            item-value="categoryGuid"
            :placeholder="$t('exam.selectQuestionsBank')"
            v-model="selectedCategory"
          >
            <template v-slot:item="{ item }">
              <span> {{ item.categoryName }} </span>
              <span v-if="item.categoryDescription" class="px-2">
                ({{ item.categoryDescription }})</span
              >
              <v-spacer></v-spacer>
              <v-chip outlined color="green" v-if="item.studySubject">
                <span>{{ item.studySubject.subjectName }}</span>
              </v-chip>
            </template>
            <template v-slot:append-outer>
              <v-checkbox
                class="mt-0 pt-0"
                id="allBanks"
                v-model="allBanks"
                hide-details
                :label="$t('all')"
                :key="allBanks"
                @change="getBankCategories"
              >
              </v-checkbox>
            </template>
          </v-autocomplete>
          <v-btn color="primary" @click="addToBank" :disabled="!selectedCategory">
            {{ $t("add") }}
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Import Questions -->
    <v-dialog
      transition="dialog-bottom-transition"
      fullscreen
      v-model="importQuestionsDialog"
      persistent
      no-click-animation
    >
      <v-card tile>
        <v-toolbar dark color="primary" dense>
          <v-toolbar-title> {{ $t("exam.importQuestions") }} </v-toolbar-title><v-spacer></v-spacer>
          <v-btn icon dark @click="importQuestionsDialog = false; importedFile = null;
                isValidQuestion = true;
                importedQuestions = [];">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-row class="ma-2">
          <v-col cols="12" md="6">
            <v-select
              outlined
              :items="importFormatType"
              item-text="name"
              item-value="value"
              v-model="selectedFormat"
              :label="$t('exam.importFrom')"
              hide-details
              return-object
              @change="
                importedFile = null;
                isValidQuestion = true;
                importedQuestions = [];
              "
            >
            </v-select
          ></v-col>
          <v-col cols="12" md="6">
          
            <v-file-input
              @change="handleFileUpload"
              outlined
              :disabled="selectedFormat.value == null"
              v-model="importedFile"
              :rules="uploadFileRules"
              :accept="selectedFormat.value == 1 ? 'application/xml' : 'text/plain'"
              :prepend-inner-icon="
                selectedFormat.value == 1 ? 'mdi-file-xml-box' : 'mdi-format-text'
              "
              :label="$t('exam.UploadAFile')"
              :clearable="false"
              hide-details
            >
              <template v-slot:append>
                <v-card-actions style="margin-top: -7px" class="pt-0">
                  <v-btn
                    color="primary"
                    :disabled="!importedFile"
                    @click="importFileQuestions(false)"
                    :loading="uploading"
                  >
                    {{ $t("upload") }}
                    <v-icon>mdi-upload</v-icon>
                  </v-btn>
                </v-card-actions>
              </template>
            </v-file-input>
          </v-col>
          <v-col cols="12">
            <v-row class="ma-0 pb-3">
              <v-text-field
                outlined
                v-model="searchImported"
                hide-details
                append-icon="mdi-magnify"
                :label="$t('writeWhatYouSearch')"
                dense
              >
                <template v-slot:append-outer>
                  <v-card-actions style="margin-top: -6px" class="pt-0">
                    <v-btn
                      :disabled="!importedFile || importedQuestions.length == 0 || !isValidQuestion"
                      color="secondary"
                      @click="importFileQuestionsSave()"
                      :loading="uploading"
                    >
                      {{ $t("save") }}
                      <v-icon class="px-2">mdi-content-save</v-icon>
                    </v-btn>
                  </v-card-actions>
                </template>
              </v-text-field>
            </v-row>
            <v-data-table
              :headers="headersImport"
              :items="importedQuestions"
              :search="searchImported"
              width="100%"
              item-key="question"
            >
              <template v-slot:[`item.question`]="{ item }">
                <v-list-item three-line>
                  <v-list-item-avatar
                    tile
                    size="100"
                    color="grey"
                    v-if="item.containsFile && item.fileDataBase64"
                  >
                    <v-img max-width="100" :src="'data:image/png;base64,' + item.fileDataBase64">
                    </v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-subtitle :title="$t('exam.alreadyExists')">
                      <v-icon class="pa-1 mb-1" color="orange" v-if="item.alreadyAdded"
                        >mdi-alert-outline
                      </v-icon>

                      {{ item.question }}
                      <p style="color: red" v-if="item.alreadyAdded">
                        {{ $t("exam.alreadyExists") }}
                      </p>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:[`item.questionType`]="{ item }">
                <v-chip
                  :title="
                    item.questionAnswers != null
                      ? item.questionAnswers.map(i => i.questionAnswer).join('\n')
                      : null
                  "
                >
                  {{
                    `${
                      item.questionType == "MultiChoice"
                        ? $t("exam.MultipleChoices")
                        : item.questionType == "OneChoice"
                        ? $t("exam.ChooseOne")
                        : item.questionType == "FillInTheBlanks"
                        ? $t("exam.FillInTheBlank")
                        : item.questionType == "Number"
                        ? $t("exam.FillInTheBlankNumericValue")
                        : item.questionType == "AttachedFile"
                        ? $t("exam.UploadAFile")
                        : ""
                    } `
                  }}</v-chip
                >
              </template>
              <template v-slot:[`item.questionAnswers`]="{ item }">
                <v-list dense v-if="item.questionAnswers && item.questionAnswers.length > 0">
                  <v-list-item
                    link
                    style="min-height: 0px"
                    v-for="answer in item.questionAnswers"
                    :key="answer.questionAnswer"
                  >
                    <v-list-item-icon class="me-0 my-0">
                      <v-icon small :color="answer.isCorrect ? 'primary' : 'secondary'">
                        {{ answer.isCorrect ? "mdi-check-bold" : "mdi-close-thick" }}
                      </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content class="py-0">
                      <v-list-item-title>{{ answer.questionAnswer }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog 
    transition="dialog-bottom-transition"
      fullscreen
      v-model="validationDialogShow"
      persistent
      no-click-animation
      >
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-toolbar-title> {{ $t("exam.invalidImportQuestions") }} </v-toolbar-title><v-spacer></v-spacer>
          <v-btn icon dark @click="validationDialogShow = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-5">
          <div
              dir="auto"
              v-html="highlightedContentWithLineNumbers"
              class="highlighted-editor"
            ></div>

        </v-card-text>
      </v-card>

    </v-dialog>
  </v-container>
</template>

<script>
import AddQuestion from "./addQuestion.vue";
import DownloadFile from "../downloadFile.vue";
import QuestionTitle from "./questionTitle.vue";

export default {
  components: {
    AddQuestion,
    DownloadFile,
    QuestionTitle
  },
  props: [
    "examQuestions",
    "examGuid",
    "isActive",
    "isBankQuestions",
    "categoryGuid",
    "subjectGuid"
  ],
  data() {
    return {
      errorsEditorContent: [],
      highlightedContentWithLineNumbers: "",
      currentView: "large",
      rowKeysSelected: [],
      showScrollTop: false,
      expanded: [],
      headersImport: [
        {
          text: this.$t("exam.theQuestion"),
          value: "question",
          show: true
        },
        {
          text: this.$t("exam.TypeOfQuestion"),
          value: "questionType",
          show: true
        },
        { text: this.$t("exam.TheDegreeOfTheQuestion"), value: "questionMark",show: true },
        {
          text: this.$t("exam.choices"),
          value: "questionAnswers",
          show: true
        }
      ],
      searchImported: "",
      fab: false,
      uploading: false,
      selectedFormat: {},
      importedQuestions: [],
      importFormatType: [
        { name: "Aiken format", value: 0 },
        { name: "Moodle XML format", value: 1 }
      ],
      importQuestionsDialog: false,
      importedFile: null,
      uploadFileRules: [
        value => !value || value.size < 30000000 || this.$t("uploadDegreesFileXlsxSize")
      ],
      overlay: false,
      search: "",
      questionsSearch: "",
      allBanks: false,
      page: 1,
      perPage: 15,
      dialogBank: false,
      dialogBankAlreadyAdded: false,
      dialogBankAddQuestionToBank: false,
      bankCategories: [],
      bankQuestions: [],
      selectedQuestions: [],
      selectedCategory: null,
      loadingData: false,
      questionAddToBankGuid: null,
      headers: [
        { text: this.$t("exam.theQuestion"), value: "question" },
        { text: this.$t("exam.TypeOfQuestion"), value: "questionType" },
        { text: this.$t("exam.QuestionMark"), value: "questionMark" },
        { text: this.$t("exam.QuestionHint"), value: "questionHint" },
        { text: this.$t("exam.TheDegreeOfTheQuestion"), value: "questionMark" },
        { text: this.$t("exam.lastUsedDate"), value: "lastUsedDate" }
      ],
      headersQuestions: [
        { text: "#", sortable: false, value: "rowActions" },
        { text: this.$t("exam.theQuestion"), value: "question" },
        { text: this.$t("exam.TypeOfQuestion"), value: "questionType" },
        { text: this.$t("exam.QuestionHint"), value: "questionHint" },
        { text: this.$t("options"), value: "questionAnswers" },
        { text: this.$t("exam.TheDegreeOfTheQuestion"), value: "questionMark" }
      ],
      alreadyAddedQuestions: [],
      isValidQuestion: true,
      questionFileContent:"",
      validationDialogShow:false
    };
  },
  computed: {
    visiblePages() {
      return this.questionsFilter.slice((this.page - 1) * this.perPage, this.page * this.perPage);
    },
    questionsFilter() {
      const data = this.examQuestions;
      // .sort((a, b) =>
      //   a.createdDate < b.createdDate ? 1 : -1
      // );
      this.page = 1;
      if (this.search) {
        return data.filter(item =>
          this.search
            .toLowerCase()
            .split(" ")
            .every(
              v =>
                item.question.toLowerCase().includes(v) ||
                item.questionType.toLowerCase().includes(v) ||
                (item.questionHint && item.questionHint.toLowerCase().includes(v)) ||
                (item.questionAnswers &&
                  item.questionAnswers.find(
                    m => m.questionAnswer && m.questionAnswer.toLowerCase().includes(v)
                  ))
            )
        );
      } else {
        return data;
      }
    }
  },
  methods: {
    handleFileUpload(event) {
      if (event) {
        const reader = new FileReader();
        reader.onload = e => {
          this.questionFileContent = e.target.result;
          this.checkAikenFormat();
        };
        reader.readAsText(event);
      }
    },
    checkAikenFormat() { 
      if(this.selectedFormat.value!=0){
        this.isValidQuestion = true;
        this.importedQuestions = [];
        return;
      }


      var editorContent=this.questionFileContent;
      this.errorsEditorContent = [];
      const lines = editorContent.split("\n").filter(line => line.trim() !== "");
      let questionLine = true;
      let currentOptions = [];
      let currentQuestion = null;
      let formattedContent = "";
      let errorLines = new Set();
      let optionLetters = [];
      let correctOption = null;
      let lineError = "";
     
      lines.forEach((line, index) => {
        const trimmedLine = line.trim();
        let lineNumber = index + 1; // Line number starts from 1

        if (questionLine) {
          if (trimmedLine === "") {
            lineError = this.$t('exam.EmptyQuestionAtLine')+` ${lineNumber}`;
            this.errorsEditorContent.push(lineError);
            errorLines.add(index);
          } else {
            currentQuestion = trimmedLine;
            questionLine = false;
          }
        } else if (/^[A-Z](\.|\))\s/.test(trimmedLine)) {
          const optionLetter = trimmedLine.split(/\.|\)/)[0].trim();
          if (!optionLetters.includes(optionLetter)) {
            optionLetters.push(optionLetter);
          }
          currentOptions.push(trimmedLine);
        } else if (trimmedLine.startsWith("ANSWER :")) {
          lineError =this.$t('exam.TheAnswerLineMustStartWith')+ ` "ANSWER: "'`;
          this.errorsEditorContent.push(lineError);
          errorLines.add(index);
        } else if (trimmedLine.startsWith("MARK :")) {
          lineError = this.$t('exam.TheMarkLineMustStartWith')+` "MARK: "'`;
          this.errorsEditorContent.push(lineError);
          errorLines.add(index);
        } else if (trimmedLine.startsWith("MARK: ")) {
          var mark = trimmedLine.split(":")[1].trim();
          var markValue = parseFloat(mark);
          if (isNaN(markValue) || markValue < 0 || markValue > 100) {
            lineError =  this.$t('exam.TheMarkValueIsInvalid');
            this.errorsEditorContent.push(lineError);
            errorLines.add(index);
          }
        } else if (trimmedLine.startsWith("ANSWER: ")) {
          correctOption = trimmedLine.split(":")[1].trim();
          if (!optionLetters.includes(correctOption)) {
            lineError = this.$t('exam.InvalidAnswerAtLineForQuestionAvailableOptions',{correctOption,lineNumber,currentQuestion})+` ${optionLetters.join(
              ", "
            )}`;
            this.errorsEditorContent.push(lineError);
            errorLines.add(index);
          }
          if (currentOptions.length < 2) {
            lineError = this.$t('exam.InsufficientOptionsForQuestionAtLine',{currentQuestion,lineNumber}) ;
            this.errorsEditorContent.push(lineError);
            errorLines.add(index);
          }
          questionLine = true;
          currentOptions = [];
          optionLetters = [];
        } else {
          lineError = this.$t('exam.UnexpectedFormatAtLine')+` ${lineNumber}`;
          this.errorsEditorContent.push(lineError);
          errorLines.add(index);
        }

        // Format the line with error if necessary
        if (errorLines.has(index)) {
          formattedContent += `<span title='${lineError}' class="errorLine"><span style='color: #9e9e9e;'>${lineNumber}</span>   ${line}</span>\n`;
        } else {
          formattedContent += `<span><span style='color: #9e9e9e;'>${lineNumber}</span>    ${line}</span>\n`;
        }
        if (trimmedLine.startsWith("ANSWER: ")) {
          formattedContent += "<hr/>\n";
        }
      });
      if (errorLines.size > 0) {
        this.isValidQuestion = false;
        this.validationDialogShow=true;
        this.importedFile = null;
      }else{
        this.isValidQuestion = true;
      }

      this.highlightedContentWithLineNumbers = formattedContent.replace(/\n/g, "<br>");
    },

    deleteQuestion(item) {
      this.$swal
        .fire({
          title: this.$t("AreYouSureAboutTheOperation"),
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no")
        })
        .then(result => {
          if (result.value) {
            this.axios
              .delete(`Exams/DeleteQuestion?questionGuid=${item}`)
              .then(res => {
                if (res.data.statusCode == 1) {
                  this.sendResultValues();
                  this.$swal.fire({
                    title: this.$t("operationAccomplishedSuccessfully"),
                    text: "",
                    icon: "success",
                    confirmButtonText: this.$t("close"),
                    toast: true,
                    position: "bottom-start",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: toast => {
                      toast.addEventListener("mouseenter", this.$swal.stopTimer);
                      toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                    }
                  });
                } else if (res.data.statusCode == 2) {
                  this.$swal.fire({
                    title: this.$t("errors.YouCanNotDeleteTheQuestionTheExamIsActiv"),
                    text: "",
                    icon: "error",
                    confirmButtonText: this.$t("close")
                  });
                  this.$emit("send-result-values", "carrier");
                } else if (res.data.statusCode == 3) {
                  this.$swal.fire({
                    title: this.$t("errors.ExamNotFound"),
                    text: "",
                    icon: "error",
                    confirmButtonText: this.$t("close")
                  });
                  this.$emit("send-result-values", "carrier");
                } else if (res.data.statusCode == 4) {
                  this.$swal.fire({
                    title: this.$t(
                      "errors.YouCanNotDeleteTheQuestionItHasBeenAnsweredByTheStudents"
                    ),
                    text: "",
                    icon: "error",
                    confirmButtonText: this.$t("close")
                  });
                  this.$emit("send-result-values", "carrier");
                } else {
                  this.$swal.fire({
                    title: this.$t("errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"),
                    text: "",
                    icon: "error",
                    confirmButtonText: this.$t("close")
                  });
                  this.$emit("send-result-values", "carrier");
                }
                // console.log(res);
              })
              .catch(() => {
                this.$swal.fire({
                  title: this.$t("AnErrorOccurredDuringTheProcess"),
                  text: "",
                  icon: "error",
                  confirmButtonText: this.$t("close")
                });
              });
          }
        });
    },
    editQuestion(item) {
      item.categoryGuid = this.categoryGuid;
      item.isBankQuestions = this.isBankQuestions;
      this.$refs.addQuestionComponent.editValue(this.examGuid, item);
    },
    downloadFileAttachment(item) {
      window.open(`${item.fileUrl}`);
      return;

      this.axios({
        url: `${item.fileUrl}`,
        method: "GET",
        responseType: "blob"
      })
        .then(response => {
          const blob = new Blob([response.data], {
            type: response.data.type
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = item.question;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("TheFileDoesNotExist"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close")
          });
        });
    },
    get_url_extension(url) {
      return url
        .split(/[#?]/)[0]
        .split(".")
        .pop()
        .trim()
        .toLowerCase();
    },
    openAddQuestion() {
      this.$refs.addQuestionComponent.setValue(
        this.examGuid,
        this.categoryGuid,
        this.isBankQuestions
      );
    },
    getBankCategories() {
      this.selectedQuestions = [];
      let url = "Exams/QuestionsBankCategories?activeStatus=1";
      if (!this.allBanks && this.subjectGuid) {
        url += `&&subjectGuid=${this.subjectGuid}`;
      }
      this.axios
        .get(url)
        .then(response => {
          this.bankCategories = response.data.data;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {});
    },
    getBankQuestion() {
      this.selectedQuestions = [];
      if (this.selectedCategory == null) return;
      this.loadingData = true;

      this.axios
        .get(`Exams/GetBankQuestions?categoryGuid=${this.selectedCategory}`)
        .then(res => {
          this.bankQuestions = res.data.data;
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close")
          });
        })
        .finally(() => {
          this.loadingData = false;
        });
    },
    openAddQuestionFromBank() {
      this.dialogBank = true;
      this.getBankQuestion();
    },
    addToBankDialog(questionGuid) {
      this.questionAddToBankGuid = questionGuid;
      this.dialogBankAddQuestionToBank = true;
    },
    addToBank() {
      if (this.isBankQuestions) return;

      const dataSave = {
        questionGuid: [this.questionAddToBankGuid],
        categoryGuid: this.selectedCategory
      };

      this.axios
        .post("/Exams/AddQuestionsToBank", dataSave)
        .then(res => {
          if (res.data.statusCode == 1) {
            this.dialogBankAddQuestionToBank = false;

            this.alreadyAddedQuestions = [];
            if (res.data.data && res.data.data.alreadyAddQuestions.length > 0) {
              this.$swal.fire({
                title: this.$t("exam.alreadyAddedTheQuestionsFromTheBank"),
                text: "",
                icon: "warning",
                confirmButtonText: this.$t("close"),
                toast: true,
                position: "bottom-start",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: toast => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                }
              });

              const questions = res.data.data.alreadyAddQuestions.map(m => m.questionGuid);

              this.dialogBankAlreadyAdded = true;
              this.alreadyAddedQuestions = this.examQuestions.filter(item => {
                if (questions.includes(item.questionGuid)) {
                  return item;
                }
              });
            } else {
              this.$swal.fire({
                title: this.$t("operationAccomplishedSuccessfully"),
                text: "",
                icon: "success",
                confirmButtonText: this.$t("close"),
                toast: true,
                position: "bottom-start",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: toast => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                }
              });
            }
          } else if (res.data.statusCode == 2) {
            this.$swal.fire({
              title: this.$t("errors.TheExamIsNotActive"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close")
            });
          } else {
            this.$swal.fire({
              title: this.$t("errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close")
            });
          }
        })
        .catch(e => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: e,
            icon: "error",
            confirmButtonText: this.$t("close")
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addSelectedQuestionsFromBank() {
      if (this.isBankQuestions) {
        return;
      }
      this.$swal
        .fire({
          title: this.$t("AreYouSureAboutTheOperation"),
          text: this.$t("exam.youWillAddQuestionsFromTheBank", {
            0: this.selectedQuestions.length
          }),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no")
        })
        .then(result => {
          if (result.value) {
            const questionsGuid = this.selectedQuestions.map(m => m.questionGuid);
            const dataSave = {
              questionGuid: questionsGuid,
              examGuid: this.examGuid
            };

            this.axios
              .post("/Exams/AddQuestionsFromBank", dataSave)
              .then(res => {
                if (res.data.statusCode == 1) {
                  this.alreadyAddedQuestions = [];
                  if (res.data.data && res.data.data.alreadyImportQuestions.length > 0) {
                    this.$swal.fire({
                      title: this.$t("exam.alreadyAddedTheQuestionsFromTheBank"),
                      text: "",
                      icon: "warning",
                      confirmButtonText: this.$t("close"),
                      toast: true,
                      position: "bottom-start",
                      showConfirmButton: false,
                      timer: 3000,
                      timerProgressBar: true,
                      didOpen: toast => {
                        toast.addEventListener("mouseenter", this.$swal.stopTimer);
                        toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                      }
                    });

                    const questions = res.data.data.alreadyImportQuestions.map(m => m.questionGuid);

                    this.dialogBankAlreadyAdded = true;
                    this.alreadyAddedQuestions = this.bankQuestions.examQuestionsBank.filter(
                      item => {
                        if (questions.includes(item.questionGuid)) {
                          return item;
                        }
                      }
                    );
                  } else {
                    this.$swal.fire({
                      title: this.$t("operationAccomplishedSuccessfully"),
                      text: "",
                      icon: "success",
                      confirmButtonText: this.$t("close"),
                      toast: true,
                      position: "bottom-start",
                      showConfirmButton: false,
                      timer: 3000,
                      timerProgressBar: true,
                      didOpen: toast => {
                        toast.addEventListener("mouseenter", this.$swal.stopTimer);
                        toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                      }
                    });
                  }

                  this.sendResultValues();
                  this.selectedQuestions = [];
                } else if (res.data.statusCode == 2) {
                  this.$swal.fire({
                    title: this.$t("errors.TheExamIsNotActive"),
                    text: "",
                    icon: "error",
                    confirmButtonText: this.$t("close")
                  });
                } else {
                  this.$swal.fire({
                    title: this.$t("errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"),
                    text: "",
                    icon: "error",
                    confirmButtonText: this.$t("close")
                  });
                }

                // console.log(res);
              })
              .catch(() => {
                this.$swal.fire({
                  title: this.$t("AnErrorOccurredDuringTheProcess"),
                  text: "",
                  icon: "error",
                  confirmButtonText: this.$t("close")
                });
              })
              .finally(() => {
                this.loading = false;
              });
          }
        });
    },
    sendResultValues() {
      this.$emit("send-result-values", "carrier");
    },
    exportToXlsx() {
      this.loadingData = true;
      var url = `Exams/ExportQuestions?ExamGuid=${this.examGuid}&Lang=${localStorage.getItem(
        "lang"
      )}`;

      if (this.isBankQuestions) {
        var url = `Exams/ExportQuestions?CategoryGuid=${
          this.categoryGuid
        }&Lang=${localStorage.getItem("lang")}`;
      }

      this.axios({
        url: url,
        method: "GET",
        responseType: "arraybuffer"
      })
        .then(response => {
          const headers = response.headers["content-disposition"].split(";");
          const blob = new Blob([response.data], {
            type: response.headers["content-type"]
          });

          const filename = headers[1];
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          const name = decodeURIComponent(escape(filename));
          link.download = name.replace("filename=", "");
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("TheFileDoesNotExist"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close")
          });
        })
        .finally(() => {
          this.loadingData = false;
        });
    },
    importFileQuestionsSave() {
      this.$swal
        .fire({
          title: this.$t("AreYouSureAboutTheOperation"),
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no")
        })
        .then(result => {
          if (result.value) {
            this.importFileQuestions(true);
          }
        });
    },
    importFileQuestions(confirmImport) {


      this.checkAikenFormat();
      if(!this.isValidQuestion){
        return;
      }


      this.uploading = true;
      const data = new FormData();
      if (this.importedFile != null) {
        data.append("file", this.importedFile);
        data.append("formatType", this.selectedFormat.value);
        if (this.examGuid) data.append("examGuid", this.examGuid);
        if (this.categoryGuid) data.append("categoryGuid", this.categoryGuid);
        data.append("confirmImport", confirmImport);
      }
      this.axios
        .post(`Exams/ImportQuestions`, data)
        .then(res => {
          this.importedQuestions = res.data.data;
          if (confirmImport && (!this.importedQuestions || this.importedQuestions.length == 0)) {
            this.importQuestionsDialog = false;
          }
          this.sendResultValues();
        })
        .catch(e => {
          let err = "";
          if (e.response.data.details) {
            err = e.response.data.details;
          } else {
            err = e.response.data;
          }

          this.$swal.fire({
            title: this.$t("errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"),
            text: err,
            icon: "error",
            confirmButtonText: this.$t("close")
          });
        })
        .finally(() => {
          this.uploading = false;
        });
    },
    setView(view) {
      this.rowKeysSelected = [];

      this.currentView = view;
      localStorage.setItem("questionsEditMode", view);
    },
    onResize() {
      if (window.innerWidth <= 600) {
        this.currentView = "large";
      } else {
        this.currentView =
          localStorage.getItem("questionsEditMode") != "large" &&
          localStorage.getItem("questionsEditMode") != "details"
            ? "large"
            : localStorage.getItem("questionsEditMode");
      }
    },
    showAttachments(item) {
      item.openAttached = !item.openAttached;
      const indexExpanded = this.expanded.findIndex(i => i.questionGuid === item.questionGuid);
      if (indexExpanded > -1) {
        this.expanded.splice(indexExpanded, 1);
      } else {
        this.expanded.push(item);
      }
      this.$forceUpdate();
    },

    handleScroll(event) {
      if (window.scrollY > 100) {
        this.showScrollTop = true;
      } else {
        this.showScrollTop = false;
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    this.currentView =
      localStorage.getItem("questionsEditMode") != "large" &&
      localStorage.getItem("questionsEditMode") != "details"
        ? "large"
        : localStorage.getItem("questionsEditMode");

    window.addEventListener("scroll", this.handleScroll);

    this.examQuestions.forEach(element => {
      element.openAttached = false;
    });

    this.getBankCategories();
  }
};
</script>

<style>
label[for="allBanks"] {
  top: 0 !important;
}
.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none;
}
.errorLine {
  background-color: #ffaea8 !important;
  font-weight: bold;
}
.theme--dark .errorLine {
  background-color: #7f3939  !important;
  font-weight: bold;
}
</style>

<style scoped>
.editor {
  width: 100%;
  height: 200px;
  font-family: monospace;
  white-space: pre;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
}

.errors {
  margin-top: 20px;
  color: red;
}

.highlighted-editor {
  width: 100%; 
  max-height: calc(100vh - 90px);
  font-family: monospace;
  white-space: pre-wrap;
  border: 1px solid #ccc;
  background-color: #f5f5f5;
  padding: 10px;
  overflow: auto;
}
.theme--dark .highlighted-editor {
    width: 100%;
    max-height: calc(100vh - 90px);
    font-family: monospace;
    white-space: pre-wrap;
    border: 1px solid #3a3a3a;
    background-color: #000000;
    padding: 10px;
    overflow: auto;
}

.lineNumber {
  color: #9e9e9e !important;
}
</style>
